<template>
  <div class="listData">
    <ul class="audit" v-if="isApproval">
      <li></li>
      <li v-for="(item, idx) of newTableData" :key="idx">
        <el-button
          class="not_go"
          v-if="
            item.state == 3 ||
            item.check_state == 1 ||
            item.check_status == 2 ||
            item.check_status == 4
          "
          type="text"
          icon="el-icon-warning"
          @click="dialogShow(idx)"
        ></el-button>
        <el-button
          class="wait"
          v-if="
            (item.state == 2 ||
              item.check_state == 0 ||
              item.check_status == 1) &&
            item.state != 3 &&
            item.check_status != 2 &&
            item.check_state != 1
          "
          type="text"
          icon="el-icon-more"
        ></el-button>
      </li>
    </ul>
    <el-table
      border
      stripe
      header-row-class-name="header"
      :data="newTableData"
      :cell-style="{ textAlign: 'center' }"
      :cell-class-name="modifyColor"
      :default-sort="{ prop: sortAttribute }"
      @sort-change="sort_change"
    >
      <el-table-column prop="id" label="序号" width="50"></el-table-column>
      <!-- <el-table-column prop="id" label="客户头像" width="60">
        <template #default="{ row }">
          <el-image
            :src="
              row.customer_thumb
                ? `//ghf.site.yscase.com${row.customer_thumb}`
                : urlHead
            "
            fit="cover"
            style="width: 30px; height: 30px; border-radius: 50%"
            
          ></el-image>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="customer_name"
        label="客户名称"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <p @click="onClickShowPop(row.customer_id)">
            {{ row.customer_name }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="难度" width="50" :formatter="ndfun">
        <!-- <template #default="scope"
          >{{ $common.levels[scope.row.level_id - 1] }}
        </template> -->
      </el-table-column>
      <el-table-column
        prop="title"
        label="项目名称"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="主美" width="65">
        <template #default="{ row }">
          <el-popover width="100" placement="bottom" trigger="click">
            <el-select
              size="medium"
              v-model="row.id_lead"
              placeholder="待分配"
              @change="getHeadmanV2(row, $event, 4)"
            >
              <el-option
                v-for="item in id_lead"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-button
              slot="reference"
              type="text"
              size="mini"
              :class="{
                noclick: !(
                  $store.state.personal.role_id == 3 ||
                  $store.state.personal.role_id == 1
                ),
              }"
            >
              <span>{{ row.zhumei_name || "待分配" }}</span>
            </el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="组长" width="65">
        <template #default="{ row }">
          <el-popover width="100" placement="bottom" trigger="click">
            <el-select
              size="medium"
              v-model="row.id_headman"
              placeholder="待分配"
              @change="getHeadman(row, $event, 1)"
            >
              <el-option
                v-for="item in id_headman"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-button
              slot="reference"
              type="text"
              size="mini"
              :class="{
                noclick: !(
                  $store.state.personal.role_id == 3 ||
                  $store.state.personal.role_id == 1
                ),
              }"
            >
              <span
                :class="
                  row.id_headman_date &&
                  new Date(nowDate).getTime() >
                    new Date(
                      row.id_headman_date.replace(/-/g, '/')
                    ).getTime() &&
                  row.headman_name
                    ? 'nowDate'
                    : ''
                "
                >{{ row.headman_name || "待分配" }}</span
              >
            </el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column class-name="painter" label="画师">
        <template #default="{ row }">
          <el-popover
            @show="showFun(row)"
            @hide="getHeadman(row, row.workersList, 2)"
            width="200"
            placement="bottom"
            trigger="click"
          >
            <el-select
              size="medium"
              v-model="row.workersList"
              placeholder="待分配"
              multiple
              :multiple-limit="6"
            >
              <el-option
                v-for="item in workersList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <div
              slot="reference"
              :class="{
                noclick: !(
                  $store.state.personal.role_id == 3 ||
                  $store.state.personal.role_id == 1
                ),
              }"
            >
              <el-button
                v-for="item of row.workers"
                :key="item.id"
                type="text"
                size="mini"
              >
                <span
                  :class="
                    row.id_workers_date != null &&
                    new Date(nowDate).getTime() >
                      new Date(row.id_workers_date.replace(/-/g, '/')).getTime()
                      ? 'nowDate'
                      : ''
                  "
                  >{{ item.name
                  }}<span
                    :class="
                      row.id_workers_date != null &&
                      new Date(nowDate).getTime() >
                        new Date(
                          row.id_workers_date.replace(/-/g, '/')
                        ).getTime()
                        ? 'nowDate1'
                        : ''
                    "
                    >({{ item.cnt }})</span
                  ></span
                >
              </el-button>
              <el-button
                v-if="
                  !row.workers.length &&
                  (row.id_workers == 'undefined' ||
                    row.id_workers == '' ||
                    !(id == 20 || id == 21))
                "
                type="text"
                size="mini"
                >待分配</el-button
              >
              <el-button
                v-if="
                  row.id_workers_date != null &&
                  new Date(nowDate).getTime() >
                    new Date(
                      row.id_workers_date.replace(/-/g, '/')
                    ).getTime() &&
                  row.id_workers != ''
                "
                type="text"
                size="mini"
              >
                <span
                  :class="
                    row.id_workers_date != null &&
                    new Date(nowDate).getTime() >
                      new Date(row.id_workers_date.replace(/-/g, '/')).getTime()
                      ? 'nowDate'
                      : ''
                  "
                  >{{ yesterdayWorkers(row) }}</span
                >
              </el-button>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="progress"
        label="预期进度"
        width="50"
      ></el-table-column> -->
      <el-table-column prop="progress_per" label="实际进度" width="50">
        <template #default="{ row }">
          <input
            type="number"
            name=""
            id=""
            v-model="row.input_value"
            :disabled="inpDisa(row)"
            :placeholder="row.progress_per"
            @blur="inpBlur(row)"
          />
          <span>%</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="项目状态">
        <template #default="{ row }" >
          <el-select v-model="row.status" placeholder="请选择" :disabled="remark1(row)" :formatter="ztfun" @change="lab(row)">
            <el-option
            
              v-for="item in progressList"
              :key="item.value"
              :label="item.label"
              :value="item.value"

            >
            </el-option>
          </el-select>
        </template> -->
      <el-table-column label="项目状态" :formatter="ztfun">
        <!-- <template #default="scope"
          >{{ $common.status[scope.row.status] }}
        </template> -->
      </el-table-column>
      <el-table-column prop="descr" label="备注" show-overflow-tooltip>
        <!-- <template #default="scope">
          <div @click="descrModify(scope.row, scope.$index)">
            <span v-if="descrModifyIndex != scope.$index">{{
              scope.row.descr
            }}</span>
            <el-input
              :ref="'descrModify' + scope.$index"
              v-model="scope.row.descr"
              v-if="descrModifyIndex == scope.$index"
              @blur="clearModifyIndex(scope.row, scope.$index)"
            ></el-input>
          </div>
        </template> -->
        <template #default="{ row }">
          <input
            name=""
            id=""
            v-model="row.descr"
            @click="clearclick(row)"
            :disabled="remark1(row)"
            @blur="clear(row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="开始时间"
        sortable
        prop="date_start"
        label-class-name="timeClass"
        width="65px"
      >
        <template #default="scope"
          >{{ $common.getDate(scope.row.date_start) }}
        </template>
      </el-table-column>
      <el-table-column
        label="草稿时间"
        prop="date_start_draft"
        sortable
        width="103"
      >
        <template #default="scope"
          >{{
            scope.row.date_end_draft
              ? $common.getDate(
                  scope.row.date_start_draft,
                  scope.row.date_end_draft
                )
              : "——"
          }}
        </template>
      </el-table-column>
      <el-table-column
        label="色稿时间"
        prop="date_start_color"
        sortable
        width="103"
      >
        <template #default="scope"
          >{{
            scope.row.date_end_color
              ? $common.getDate(
                  scope.row.date_start_color,
                  scope.row.date_end_color
                )
              : "——"
          }}
        </template>
      </el-table-column>
      <el-table-column
        label="细化时间"
        prop="date_start_refine"
        sortable
        width="103"
      >
        <template #default="scope"
          >{{
            scope.row.date_end_refine
              ? $common.getDate(
                  scope.row.date_start_refine,
                  scope.row.date_end_refine
                )
              : "——"
          }}
        </template>
      </el-table-column>
      <el-table-column
        label="结束时间"
        sortable
        prop="date_end"
        label-class-name="timeClass"
        width="65px"
      >
        <template #default="scope"
          >{{ $common.getDate(scope.row.date_end) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" class-name="operate" width="80">
        <template #default="scope">
          <slot :project="scope.row.id" :data="scope.row"></slot>
          <el-button
            v-if="isBtnNum[0]"
            type="text"
            icon="el-icon-tickets"
            @click="examine(scope.row.id)"
          ></el-button>
          <el-button
            v-if="
              isBtnNum[1] &&
              !(scope.row.status == 0 && scope.row.state == 2) && //判断发布待审核
              ($store.state.personal.role_id == 2 ||
                $store.state.personal.role_id == 1) &&
              scope.row.isEdit != -1
            "
            type="text"
            icon="el-icon-edit-outline"
            @click="addProject(scope.row.id, scope.row)"
          ></el-button>
          <el-button
            class="red"
            v-if="
              isBtnNum[2] &&
              ($store.state.personal.role_id == 1 ||
                $store.state.personal.role_id == 2) &&
              scope.row.isEdit != -1
            "
            type="text"
            icon="el-icon-delete"
            @click="remove(scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="dialogVisible" width="800px" top="10vh">
      <div class="state">
        <div>审核状态：<span class="red">未通过</span></div>
        <div v-if="dialogObj.check_list">
          审核人员：管理员<span class="red">
            {{ dialogObj.check_list[0].name }}</span
          >
        </div>
      </div>
      <div class="cause">
        <div>原因：</div>
        <div>
          {{ dialogObj.check_note }}
        </div>
      </div>
      <div class="affix" v-if="dialogObj.check_pics">
        <div>附件：</div>
        <div>
          <el-image
            v-for="(url, idx) of dialogObj.check_pics"
            :key="idx"
            :src="'//ghf.site.yscase.com/' + imgsrc({ name: url, url })"
          ></el-image>
        </div>
      </div>
      <div class="history">
        <div>历史审批：</div>
        <div>
          <el-timeline>
            <el-timeline-item
              v-for="(item, idx) of dialogObj.check_list"
              :key="idx"
              :timestamp="changeTime(item.add_time)"
              placement="top"
              v-show="idx != 0"
            >
              <el-card v-if="idx != 0">
                <p>
                  {{ item.check_note }}
                </p>
                <ul class="image">
                  <li v-for="(url, i) of item.pics" :key="i">
                    <img
                      :src="
                        '//ghf.site.yscase.com/' + imgsrc({ name: url, url })
                      "
                    />
                  </li>
                </ul>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      width="60%"
      :visible.sync="dialogFormVisible"
      @closed="closed"
      id="customerDetails"
    >
      <div>
        <el-image
          :src="
            form.thumb
              ? `//ghf.site.yscase.com${form.thumb}`
              : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
          "
          fit="cover"
        ></el-image>
        <div
          v-if="compile && (getRole_id == 1 || getRole_id == 6)"
          class="operation"
        >
          <el-button round @click="compile = false">编辑</el-button>
          <el-button round @click="remove(form.customer_id)">删除</el-button>
        </div>
        <div v-else-if="getRole_id == 1 || getRole_id == 6" class="operation">
          <el-button type="success" round @click="saveBtn">保存</el-button>
          <el-button type="danger" round @click="cancel">取消</el-button>
        </div>
      </div>
      <el-form :model="form" label-width="auto" :disabled="compile">
        <el-form-item label="公司名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="form.lxr"></el-input>
        </el-form-item>
        <el-form-item
          label="联系方式"
          v-if="getRole_id == 1 || getRole_id == 2 || getRole_id == 6"
        >
          <el-input v-model="form.tel"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="合作时机">
          <el-input v-model="form.hzsj"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="3"
            resize="none"
            v-model="form.note"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-divider direction="vertical"></el-divider>
      <div class="information">
        <h6>客户的项目</h6>
        <p>
          该客户项目共计
          <b>{{ form.total }}</b>
          项
        </p>
        <ProjectSelectionList
          :list="myDatas"
          @screen="
            (val) => {
              clientScreen(val, form.customer_id);
            }
          "
        ></ProjectSelectionList>
        <el-button type="info" round @click="examine(form.customer_id)"
          >查看该客户的全部项目</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisibleReview"
      width="500px"
      class="shenheno-go"
      top="15vh"
    >
      <!-- 主美 -->
      <!-- <p v-if="getRole_id!=1">审核驳回原因：{{ noGoReview.check_reason }}</p> -->
      <p>
        审核驳回原因：{{ noGoReview.check_note }}{{ noGoReview.check_reason }}
      </p>
    </el-dialog>
  </div>
</template>
<script>
import ProjectSelectionList from "./ProjectSelectionList.vue";
export default {
  props: {
    tableData: Array,
    isBtn: { type: String, default: "123" },
    isApproval: { type: Boolean, default: false },
    callBack: Function,
  },
  data() {
    return {
      newTableData: [],
      noGoReview: {},
      dialogVisibleReview: false,
      getRole_id: null,
      levels: ["C", "B", "A", "S"],
      // 项目状态
      // status ： ["待开始", "进行中", "暂停", "完成"];
      status: ["待开始", "进行中", "有提交", "有反馈", "结束"],
      dialogVisible: false,
      len: 0,
      id_headman: [],
      id_lead: [],
      workersList: [],
      descrModifyIndex: -1,
      OriginallyDesc: "",
      nowDate:
        new Date().getFullYear() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getDate(),
      id: sessionStorage.getItem("id"),
      urlHead: "//cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      form: {
        company: "",
        linkman: "",
        contact: "",
        address: "",
        oppo: "",
        remark: "",
        hzsj: "",
      },
      dialogFormVisible: false,
      compile: true,
      myDatas: "",
      sortAttribute: null,
      dialogObj: {},
    };
  },
  computed: {
    isBtnNum() {
      let a, b, c;
      let arr = [];
      switch (this.isBtn.length) {
        case 1:
          this.isBtn === "1" && (a = 1);
          this.isBtn === "2" && (b = 2);
          this.isBtn === "3" && (c = 3);
          return [a, b, c];
        case 2:
          arr = this.isBtn.split("");
          arr = arr.sort((a, b) => {
            return a - b;
          });
          console.log(arr);
          if (arr.indexOf("3") === -1) {
            [a, b, c] = arr;
          } else if (arr.indexOf("2") === -1) {
            a = arr[0];
            c = arr[1];
          } else if (arr.indexOf("1") === -1) {
            b = arr[0];
            c = arr[1];
          }
          return [a, b, c];
        // console.log();
        default:
          [a, b, c] = this.isBtn.split("").sort((a, b) => {
            return a - b;
          });
          // console.log(a, b, c);
          return [a, b, c];
      }
    },
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value * 1000);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
  methods: {


    inits() {
      console.log(this.noGoReview);
      //处理我的审批，组长显示错误
      if (this.$route.path.indexOf("review") != -1) {
        this.$common.getInterface(
          "/api/v1.workers/getList",
          { role_id: 4 },
          this,
          (res) => {
            this.id_headman = res.data.data;

            this.newTableData.map((e) => {
              this.$set(
                e,
                "headman_name",
                this.id_headman.find((n) => n.id == e.id_headman).name
              );
            });
          }
        );
      }
      {
        this.$common.getInterface(
          "/api/v1.workers/getList",
          { role_id: 4 },
          this,
          (res) => {
            this.id_headman = res.data.data;
          }
        );

        this.$common.getInterface(
          "/api/v1.workers/getList",
          { role_id: 3 },
          this,
          (res) => {
            this.id_lead = res.data.data;

            this.newTableData.map((e) => {
              e.id_lead &&
                this.$set(
                  e,
                  "zhumei_name",
                  this.id_lead.find((n) => n.id == e.id_lead).name
                );
            });
          }
        );
        for (let i = 5; i > 2; i--) {
          this.$common.getInterface(
            "/api/v1.workers/getList",
            { role_id: i },
            this,
            (res) => {
              this.workersList = this.workersList.concat([...res.data.data]);
            }
          );
        }
      }
    },
    lab(row) {
      console.log(row);
      console.log(row.status);
      console.log(this.progressList[0].label);
    },
    imgsrc(file) {
      let num = file.name.lastIndexOf(".");
      let type = file.name.substr(num);
      if (type === ".jpg" || type === ".png") {
        return file.url;
      } else if (type === ".rar" || type === ".zip") {
        return require("../assets/img/yasuobao.png");
      } else if (
        type === ".doc" ||
        type === ".docx" ||
        type === ".word" ||
        type === ".rtf"
      ) {
        return require("../assets/img/word.png");
      } else if (type === ".xls" || type === ".xlsx") {
        return require("../assets/img/xls.png");
      } else if (type === ".ppt" || type === ".pptx") {
        return require("../assets/img/ppt.png");
      } else if (type === ".pdf") {
        return require("../assets/img/pdf.png");
      } else if (type === ".txt") {
        return require("../assets/img/txt.png");
      }
    },
    changeTime(time) {
      time = time * 1000;
      let y = new Date(time).getFullYear();
      let M =
        new Date(time).getMonth() + 1 >= 10
          ? new Date(time).getMonth() + 1
          : "0" + (new Date(time).getMonth() + 1);
      let d =
        new Date(time).getDate() >= 10
          ? new Date(time).getDate()
          : "0" + new Date(time).getDate();
      let h =
        new Date(time).getHours() >= 10
          ? new Date(time).getHours()
          : "0" + new Date(time).getHours();
      let m =
        new Date(time).getMinutes() >= 10
          ? new Date(time).getMinutes()
          : "0" + new Date(time).getMinutes();
      let s =
        new Date(time).getSeconds() >= 10
          ? new Date(time).getSeconds()
          : "0" + new Date(time).getSeconds();
      return y + "/" + M + "/" + d + " " + h + ":" + m + ":" + s;
    },
    dialogShow(idx) {
      console.log(this.$route.path);
      console.log(this.newTableData[idx]);
      if (
        this.$route.path.indexOf("/review") != -1 ||
        this.$route.path.indexOf("/mytask") != -1
      ) {
        // this.noGoReview = Object(this.newTableData[idx],{});
        this.noGoReview = this.newTableData[idx];
        this.dialogVisibleReview = true;
      } else if (this.$route.path.indexOf("/release") != -1) {
        this.dialogObj = Object(this.newTableData[idx], {});
        this.dialogObj.check_list.forEach((item) => {
          item.pics =
            item.pics && typeof item.pics == String
              ? item.pics.split(",")
              : null;
        });
        this.dialogObj.check_pics =
          this.dialogObj.check_pics &&
          typeof this.dialogObj.check_pics == String
            ? this.dialogObj.check_pics.split(",")
            : null;
        this.dialogObj.check_list = this.dialogObj.check_list.reverse();
        this.dialogVisible = true;
      }
    },
    sort_change(column) {
      this.sortAttribute = column.prop;
    },
    remark1() {
      let role_id = window.sessionStorage.getItem("role_id");
      if (role_id == 2 || role_id == 1) {
        return false;
      } else {
        return true;
      }
    },
    inpDisa(row) {
      let role_id = window.sessionStorage.getItem("role_id");

      var watieArray = [1, 2];

      if (watieArray.indexOf(parseInt(role_id)) === -1) {
        //  console.log(row.id  + ": " + role_id + ":" + "权限不足")
        return true;
      }

      // if (
      //   (row.status == 0 || row.status == 2 || row.status == 3) &&
      //   row.state !== 3
      // ) {
      //   // console.log(row.id  + ": " + role_id + ":" + "状态不足")
      //   return true;
      // }
      // return false;
    },
    // 修改实际进度
    inpBlur(row) {
      console.log(row.input_value);
      //   console.log(row.id);
      console.log(
        (row.status == 0 || row.status == 2 || row.status == 3) &&
          row.state !== 3 &&
          (row.role_id !== 1 || row.role_id !== 2)
      );
      this.$common.postInterface(
        "/api/v1.projects/setProgress",
        {
          // 项目id
          project_id: row.id,
          // 实际进度
          per: row.input_value,
        },
        this,
        (res) => {
          console.log(res);
          console.log(row.status);
          console.log(row.input_value);
          if (res.status === "200") {
            setmas("修改成功", "success", this);
          } else {
            setmas(res.msg, "error", this);
          }
          // 超过限制

          if (row.input_value > 100) {
            row.input_value = null;
            setmas("请填入1-100", "error", this);
          }
        }
      );
    },
    addProject(id, item) {
      if (
        this.$route.path.indexOf("draft") == -1 &&
        this.$route.path.indexOf("release") == -1
      ) {
        sessionStorage.setItem("addProject", 1);
        this.$router.push({ path: `/audit/${id}` });
      } else {
        sessionStorage.setItem("addProjectMsg", JSON.stringify(item));
        this.$router.push({ path: `/create`, query: { id: id } });
      }
    },
    yesterdayWorkers(e) {
      let workersList = e.id_workers.split(",").map(Number);
      let workersNameList = [];
      this.workersList.forEach((item) => {
        if (workersList.indexOf(item.id) != -1) {
          workersNameList.push(item.name);
        }
      });
      return workersNameList.join(" ");
    },
    // input输入框失焦时提交修改备注
    clearclick(row) {
      this.OriginallyDesc = row.descr;
    },
    clear(row) {
      console.log(row.descr);
      this.$common.postInterface(
        "/api/v1.projects/editDescr",
        {
          id: row.id,
          descr: row.descr,
        },
        this,
        (res) => {
          console.log(row);
          if (res.status === "200") {
            setmas("修改成功", "success", this);
            this.callBack && this.callBack();
          } else {
            setmas(res.msg, "error", this);
            row.descr = this.OriginallyDesc;
          }
        }
      );
    },
    clearModifyIndex(row, index) {
      if (sessionStorage.getItem("role_id") != "2") {
        return false;
      }
      console.log(row, index);
      this.$common.postInterface(
        "/api/v1.projects/editDescr",
        {
          id: row.id,
          descr: row.descr,
        },
        this,
        (res) => {
          this.descrModifyIndex = -1;
          if (res.status === "200") {
            setmas("修改成功", "success", this);

            // this.$message({
            //   type: "success",
            //   message: "修改成功!"
            // });
            this.callBack && this.callBack();
          } else {
            setmas(res.msg, "error", this);

            // this.$message.error(res.msg);
            row.descr = this.OriginallyDesc;
          }
        }
      );
    },
    // 点击备注修改备注
    descrModify(row, index) {
      if (sessionStorage.getItem("role_id") != "2") {
        return false;
      }
      console.log(row);
      console.log(index);
      this.descrModifyIndex = index;
      this.OriginallyDesc = row.descr;
      this.$nextTick(() => {
        this.$refs[`descrModify${index}`].focus();
      });
    },
    modifyColor({ row, columnIndex }) {
      // 将开始时间赋值
      row.date_start =
        row.date_start_draft ||
        row.date_start_color ||
        row.date_start_refine ||
        row.date_end;
      //获取当前时间
      let nowDate = new Date().getTime();
      let timeNum = 1000 * 60 * 60 * 24;
      if (row.progress == 4 && columnIndex === 15 - 1) {
        if (
          new Date(row.date_end.replace(/-/g, "/") + " 23:59:59").getTime() <=
          nowDate
        ) {
          return "alert-color";
        } else if (
          (new Date(row.date_end.replace(/-/g, "/") + " 23:59:59").getTime() -
            nowDate) /
            timeNum <
          3
        ) {
          return "early-warning";
        }
      } else if (
        row.progress == 3 &&
        row.date_end_refine != null &&
        columnIndex == 14 - 1
      ) {
        if (
          new Date(
            row.date_end_refine.replace(/-/g, "/") + " 23:59:59"
          ).getTime() <= nowDate
        ) {
          return "alert-color";
        } else if (
          (new Date(
            row.date_end_refine.replace(/-/g, "/") + " 23:59:59"
          ).getTime() -
            nowDate) /
            timeNum <
          3
        ) {
          return "early-warning";
        }
      } else if (
        row.progress == 2 &&
        row.date_refine == null &&
        row.date_end_color != null &&
        columnIndex == 13 - 1
      ) {
        if (
          new Date(
            row.date_end_color.replace(/-/g, "/") + " 23:59:59"
          ).getTime() <= nowDate
        ) {
          return "alert-color";
        } else if (
          (new Date(
            row.date_end_color.replace(/-/g, "/") + " 23:59:59"
          ).getTime() -
            nowDate) /
            timeNum <
          3
        ) {
          return "early-warning";
        }
      } else if (
        row.progress == 1 &&
        row.date_color == null &&
        row.date_end_draft != null &&
        columnIndex == 12 - 1
      ) {
        if (
          new Date(
            row.date_end_draft.replace(/-/g, "/") + " 23:59:59"
          ).getTime() <= nowDate
        ) {
          return "alert-color";
        } else if (
          (new Date(
            row.date_end_draft.replace(/-/g, "/") + " 23:59:59"
          ).getTime() -
            nowDate) /
            timeNum <
          3
        ) {
          return "early-warning";
        }
      } else if (
        (row.progress >= 4 && columnIndex == 15 - 1) ||
        (row.date_refine != null &&
          columnIndex == 13 - 1 &&
          row.date_end_color) ||
        (row.date_color != null &&
          columnIndex == 12 - 1 &&
          row.date_end_draft) ||
        (row.progress > 3 && columnIndex == 14 - 1 && row.date_end_refine)
      ) {
        return "security-color";
      }
      // 判断开始时间的状态
      else if (columnIndex === 10) {
        if (
          (row.date_start_draft != null && row.date_color != null) ||
          (row.date_start_color != null && row.date_refine != null) ||
          (row.date_start_refine != null && row.progress >= 3) ||
          row.progress >= 4
        ) {
          return "security-color";
        } else if (row.date_start != null) {
          if (
            new Date(
              row.date_start.replace(/-/g, "/") + " 23:59:59"
            ).getTime() <= nowDate
          ) {
            return "alert-color";
          } else if (
            (new Date(
              row.date_start.replace(/-/g, "/") + " 23:59:59"
            ).getTime() -
              nowDate) /
              timeNum <
            3
          ) {
            return "early-warning";
          } else {
            return "";
          }
        }
      }
    },
    examine(id) {
      sessionStorage.setItem("addProject", 0);
      sessionStorage.setItem("urlName", this.$route.name);
      this.$router.push({ path: `/audit/${id}` });
    },
    remove(id) {
      this.$confirm("此操作将永久删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$common.postInterface(
            "/api/v1.projects/deleteProject",
            { project_id: id },
            this,
            (res) => {
              if (res.status === "200") {
                setmas("删除成功", "success", this);

                // this.$message({
                //   type: "success",
                //   message: "删除成功!"
                // });
                this.callBack && this.callBack();
              } else {
                setmas(res.msg, "error", this);

                // this.$message.error(res.msg);
              }
            }
          );
        })
        .catch(() => {
          setmas("已取消删除", "info", this);

          // this.$message({
          //   type: "info",
          //   message: "已取消删除"
          // });
        });
    },
    ndfun(row) {
      return this.levels[row.level_id - 1];
    },
    // 展示项目状态
    formatDate(value) {
      let date = new Date(value * 1000);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      // let h = date.getHours();
      // h = h < 10 ? "0" + h : h;
      // let m = date.getMinutes();
      // m = m < 10 ? "0" + m : m;
      // let s = date.getSeconds();
      // s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + "  "+ "  "+ "  "+ "  "+ "  "+ "  "+ " ";
    },
    ztfun(row) {
      if (!row.action_time) {
        row.action_time = "";
      } else {
          console.log()
        if (row.action_time.toString().length == 10) {
          
          row.action_time = this.formatDate(row.action_time);
        }
        // console.log(row.action_time, "dayin");
      }
      if (row.is_pause == 1) {
        return row.action_time + "已暂停";
      }
      if (row.status == 0) {
        return row.action_time + "待开始";
      }
      if (row.status == 1) {
        return row.action_time + "进行中";
      }
      if (row.status == 2) {
        return row.action_time + "有提交";
      }
      if (row.status == 3) {
        return row.action_time + "有反馈";
      }
      if (row.status == 4) {
        return row.action_time + "结束";
      }
      if (row.state == 2) {
        return row.action_time + "已发布";
      }
      if (row.state == 3) {
        return row.action_time + "已推送";
      }

      if (row.progress == 4) {
        return row.action_time + "已结束";
      }
      // let progressList = ["草稿阶段", "色稿阶段", "细化阶段", "已结束"];
      // return progressList[row.progress - 1];
      // 禁用输入框

      // return this.status[row.status];
    },
    showFun(e) {
      // e.workersList = e.id_workers.split(",").map(Number)
      // let strall = e.id_workers;
      // if (typeof e.id_workers == "string") {
      //   e.id_workers = e.id_workers.split(",").map(Number);
      // }

      let mapList = e.id_workers.split(",").map(Number);
      mapList.forEach((key, e) => {
        if (!e) mapList.splice(key, 1);
      });

      e.prveWorkers = mapList;

      let index = this.newTableData.findIndex((b) => e.id == b.id);
      this.$set(this.newTableData[index], "workersList", mapList);
    },
    // 刷新列表数据
    RefreshData() {
      this.$emit("RefreshData");
    },
    getHeadmanV2(row, val, type) {
      this.$common.postInterface(
        "/api/v1.projects/setWorker",
        { worker: val, type: type, project_id: row.id },
        this,
        (res) => {
          if (res.status == "200") {
            var find = this.id_lead.find((element) => element.id == val);
            row.zhumei_name = find.name;
            setmas(res.msg, "success", this);

            this.RefreshData();
          } else {
            setmas(res.msg, "error", this);
            this.RefreshData();
            // this.$message.error(res.msg);
          }
        }
      );
    },
    getHeadman(row, val, type) {
      console.log(row);
      if (type == 2) {
        if (Array.isArray(val)) {
          val = val.toString();
        }
        console.log(val);

        if (row.prveWorkers.toString() == val) {
          console.log("没有改变，不更新");

          // return;
        }
      }

      this.$common.postInterface(
        "/api/v1.projects/setWorker",
        { worker: val, type: type, project_id: row.id },
        this,
        (res) => {
          if (res.status == "200" && type == 1) {
            let obj = {};
            obj = this.id_headman.find((item) => {
              return item.id === val;
            });
            row.headman_name = obj.name;
            setmas(res.msg, "success", this);

            this.RefreshData();

            // this.$message.success(res.msg);
          } else if (res.status == "200" && type == 2) {
            row.workers = [];
            for (let i = 0; i < row.workersList.length; i++) {
              let t = this.workersList.find((val) => {
                return val.id == row.workersList[i];
              });
              row.workers.push({
                id: t.id,
                name: t.name,
                role_id: t.role_id,
              });
            }
            row.id_workers = row.workersList.toString();
            setmas(res.msg, "success", this);

            // this.$message.success(res.msg);
            this.RefreshData();
          } else {
            setmas(res.msg, "error", this);
            this.RefreshData();
            // this.$message.error(res.msg);
          }
        }
      );
    },
    getHeadmanBlur(row, val, type) {
      this.RefreshData();

      this.$common.postInterface(
        "/api/v1.projects/setWorker",
        { worker: val, type: type, project_id: row.id },
        this,
        (res) => {
          if (res.status == "200" && type == 1) {
            let obj = {};
            obj = this.id_headman.find((item) => {
              return item.id === val;
            });

            row.headman_name = obj.name;
            setmas(res.msg, "success", this);

            // this.$message.success(res.msg);
          }
        }
      );
    },
    onClickShowPop: function (id) {
      this.$common.getInterface(
        "/api/v1.customer/getOne",
        {
          id: id,
        },
        this,
        (res) => {
          this.form = res.data;
          this.myDatas = (() => {
            let { cnt_end, finish_per, cnt_delay, cnt_begin } = res.data;
            return { cnt_end, finish_per, cnt_delay, cnt_begin };
          })();
          this.dialogFormVisible = true;
        }
      );
    },
    closed() {
      this.compile = true;
    },
    clientScreen(val, id) {
      let dataName = "";
      let data = "";
      if (val.name == "cnt_end") {
        dataName = "status";
        data = 3;
      } else if (val.name == "cnt_delay") {
        dataName = "is_delay";
        data = 1;
      } else if (val.name == "cnt_begin") {
        dataName = "status";
        data = 1;
      }
      let Obj = {
        customer_id: id,
      };
      Obj[dataName] = data;
      sessionStorage.setItem("staffMsg", JSON.stringify(Obj));
      if (this.$route.name != "Index") {
        this.$router.push({ path: `/index` });
      } else {
        this.dialogFormVisible = false;
        let staffMsg = JSON.parse(sessionStorage.getItem("staffMsg"))
          ? JSON.parse(sessionStorage.getItem("staffMsg"))
          : {};
        this.$emit("filterData", staffMsg);
      }
    },
  },

  watch: {
    dialogVisible: function (newl) {
      if (!newl) {
        this.dialogObj = {};
      }
    },
    tableData(newl) {
      let newData = JSON.parse(JSON.stringify(newl));
      if (this.newTableData.length) {
        newData = newData.map((e, key) => {
          e = Object.assign({}, this.newTableData[key], e);
          return e;
        });
        this.newTableData = newData;
      } else {
        this.newTableData = newData;
        this.inits();
      }
    },
  },
  components: { ProjectSelectionList },
};
// 提醒弹出框
function setmas(msg, type, vm) {
  vm.$message({
    message: msg,
    type: type,
    customClass: "popup",
  });
}
</script>
<style>
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;
  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
</style>
<style lang="less" scoped>
@deep: ~">>>";
.o {
  width: 100%;
}
.cell input {
  text-align: center;
  width: 55%;
}
.el-table .painter .cell {
  white-space: pre-wrap;
}
.listData .audit[data-v-59e67cd0] li .wait {
  width: 18px;
  height: 18px;
  font-size: 12px !important;
}
.listData .audit[data-v-59e67cd0] li .el-button {
  font-size: 20px;
}
.noclick {
  pointer-events: none !important;
}
.nowDate {
  color: #999;
}
.nowDate1 {
  display: none;
}
@{deep}.el-table th.gutter {
  display: table-cell !important;
}
/deep/.early-warning {
  background-color: rgb(255, 192, 0) !important;
}
/deep/.alert-color {
  background-color: rgb(230, 0, 18) !important;
  color: #fff;
}
/deep/.security-color {
  background-color: rgb(146, 208, 80) !important;
}
.listData {
  display: flex;
  .audit {
    @{deep}li {
      padding-right: 10px;
      height: 52.5px;
      // line-height: 53px;
      display: flex;
      align-items: center;
      .el-button {
        padding: 0;
        font-size: 18px;
      }
      .not_go {
        color: #e87878;
      }
      .wait {
        background: #888;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
      }
    }
    li:first-child {
      height: 35px;
      // line-height: 34px;
    }
  }
}
.red {
  color: #e87878 !important;
}
@{deep}.el-dialog {
  box-sizing: border-box;
  padding: 0;
  padding-right: 15px;
  height: 80%;
  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      top: -8px;
      right: -60px;
      font-size: 40px;
      .el-dialog__close {
        color: #eee;
      }
    }
  }
  .el-dialog__body {
    box-sizing: border-box;
    padding: 30px;
    padding-right: 15px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 30px;
    }
    // <!--修改 滚动条的 下面 的 样式-->
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    // <!--修改 滑块 -->
    &::-webkit-scrollbar-thumb {
      background-color: #ebebeb;
      border-radius: 30px;
      // border: 5px solid #000;
      border: 11px solid #fff;
      // border-top: none;
      // border-bottom: none;
    }
    .state {
      width: 320px;
      display: flex;
      justify-content: space-between;
    }
    .cause {
      margin-top: 20px;
      display: flex;
      div:last-child {
        padding: 10px;
        width: 100%;
        height: 70px;
        background-color: #ebebeb;
        border-radius: 6px;
        font-size: 12px;
      }
      div:first-child {
        flex-shrink: 0;
        width: 70px;
        text-align: right;
      }
    }
    .affix {
      margin-top: 15px;
      display: flex;
      div:first-child {
        flex-shrink: 0;
        width: 70px;
        text-align: right;
        line-height: 80px;
      }
      div:last-child {
        .el-image {
          width: 80px;
          height: 80px;
          border-radius: 6px;
          margin-right: 15px;
        }
      }
    }
    .history {
      margin-top: 10px;
      display: flex;
      div:first-child {
        flex-shrink: 0;
      }
      & > div:last-child {
        padding-top: 5px;
        .el-timeline {
          .el-timeline-item {
            .el-timeline-item__node {
              background-color: #afafaf;
            }
            .el-card {
              margin-top: 15px;
              box-shadow: none;
              border: none;
              .el-card__body {
                padding: 0;
                .image {
                  margin-top: 10px;
                  padding-bottom: 10px;
                  border-bottom: 1px dashed #ddd;
                  display: flex;
                  li {
                    width: 135px;
                    margin-right: 14px;
                    //   height: 84px;
                    img {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.shenheno-go {
  @{deep}.el-dialog {
    height: 200px;
  }
  p {
    color: #000;
  }
}
@{deep}.el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: #e2e1e1;
}
@{deep}.el-table {
  border-radius: 10px;
  box-shadow: 0 0 10px 1px #ddd;
  // .gutter,
  // .el-table__body-wrapper::-webkit-scrollbar,
  // .el-table__body-wrapper::-webkit-scrollbar-thumb,
  // .el-table__body-wrapper::-webkit-scrollbar-track {
  //   display: none;
  // }
  .el-table__header-wrapper {
    background-color: #696c70;
    border-bottom: 1px solid #555;
    * {
      background: none;
    }
    .header {
      th {
        border-right: 1px solid #555;
        border-bottom: none;
        text-align: center;
        color: #eee;
        padding: 0;
        .cell {
          line-height: 1;
        }
        // &:last-child {
        //   border-right: none;
        // }
        &.timeClass {
          div {
            display: flex;
            align-items: center;
            // white-space: nowrap;
          }
        }
        // &:nth-last-child(2) {
        //   .cell {
        //     padding-left: 30px;
        //   }
        // }
      }
    }
  }
  .operate {
    .cell {
      display: flex;
      justify-content: space-around;

      .el-button {
        padding: 0;
        margin: 0;
        color: #666;
        // &:last-child {
        //   color: #e87878;
        // }
      }
    }
  }
  .painter {
    overflow: hidden;
    .cell {
      white-space: nowrap;
      overflow: visible;
      display: flex;
      justify-content: center;
    }
  }
  // .is-sortable {
  //   div {
  //     padding-right: 0;
  //   }
  // }
}
#customerDetails @{deep}.el-dialog {
  min-width: 1000px;
  border-radius: 10px;
  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      top: -8px;
      right: -60px;
      font-size: 40px;
      .el-dialog__close {
        color: #eee;
      }
    }
  }
  .el-dialog__body {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .el-image {
      width: 200px;
      height: 200px;
      border-radius: 100%;
      margin: 5% auto;
    }
    .operation {
      display: flex;
      justify-content: space-around;
    }
    .el-divider {
      height: 400px;
    }
    .information {
      width: 320px;
      flex-shrink: 0;
      h6 {
        font-size: 22px;
        font-weight: 400;
      }
      & > p {
        line-height: 60px;
        background-color: #ebebeb;
        text-align: center;
        font-size: 16px;
        border-radius: 10px;
        margin: 20px 10px 10px 0;
        b {
          margin: 0 5px;
          font-size: 24px;
          color: #f8b849;
        }
      }
    }
    .el-button {
      display: block;
      margin: 30px auto 0;
      font-size: 16px;
    }
  }
}
</style>
